import React from "react";
import styled, { css } from "styled-components";
import Label from "../Label";
import { Form } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { FaImage, FaTrash, FaEye, FaFilePdf } from "react-icons/fa";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

import api from "../../service/api";

export const Switch = styled(Form.Switch)`
    zoom: 2;
    font-family: var(--font-title);
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 750px) {
        zoom: 2;
    }
`;

const Input = styled.input`
    border: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background-color: var(--white);
    padding: 10px !important;
`;

const StyledSelect = styled.select`
    border: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background-color: var(--white);
    padding: 10px !important;
`;

const StyledTextArea = styled.textarea`
    border: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background-color: var(--white);
    padding: 10px !important;
`;

const Index = (props) => {
    const { id, label } = props;

    return (
        <Label htmlFor={id}>
            <span>{label}</span> <Input {...props} />
        </Label>
    );
};

export const Select = (props) => {
    const { id, label, children } = props;

    return (
        <Label htmlFor={id}>
            <span>{label}</span>{" "}
            <StyledSelect {...props}>{children}</StyledSelect>
        </Label>
    );
};

export const TextArea = (props) => {
    const { id, label } = props;

    return (
        <Label htmlFor={id}>
            <span>{label}</span> <StyledTextArea {...props} />
        </Label>
    );
};

const dragActive = css`
    border-color: var(--green);
`;

const dragReject = css`
    border-color: var(--red);
`;

export const DropContainer = styled.div.attrs({
    className: "dropzone",
})`
    width: 100%;
    height: ${(props) => props.height || "fit-content"};
    background-color: var(--color-staff);
    border-radius: 2px;
    margin: 0 auto;
    cursor: pointer;
    background-color: var(--gray-3);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    & img {
        width: 100%;
        height: 100%;
        border-radius: 2px;
        border: 2px solid #dddd;
        box-shadow: var(--shadow-default);
        ${(props) => props.isDragActive && dragActive};
        ${(props) => props.isDragReject && dragReject};
    }

    & .viewer-layout-container {
        width: 100%;
        height: 100%;
        border-radius: 2px;
        border: 2px solid #dddd;
        box-shadow: var(--shadow-default);
        ${(props) => props.isDragActive && dragActive};
        ${(props) => props.isDragReject && dragReject};
        grid-template-rows: none;
        zoom: 0.5;
    }

    & .viewer-layout-container .viewer-layout-toolbar {
        display: none;
    }
`;

const FileGrid = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 24px !important;

    width: ${(props) => props.width || "200px"};
    & span {
        font-family: var(--font-title);
        font-weight: 600;
    }

    & span {
        height: auto;
    }
`;

const FileControl = styled.div.attrs({
    className: "row",
})`
    height: 32px;
    font-size: 1.5rem;
    width: 100%;
`;

const DeleteFile = styled.div.attrs({
    className: "col d-flex justify-center align-items-center cursor-pointer",
})`
    color: var(--red);
    border: 2px solid var(--red);
    background-color: var(--gray-4);
    transition: 200ms;

    &:hover {
        filter: brightness(130%);
    }

    &.file-disabled {
        filter: brightness(75%);
    }
`;

const ViewFile = styled.div.attrs({
    className: "col d-flex justify-center align-items-center cursor-pointer",
})`
    background-color: var(--blue);
    color: var(--gray-1);
    transition: 200ms;

    &:hover {
        filter: brightness(130%);
    }

    &.file-disabled {
        filter: brightness(75%);
    }

    & a {
        color: var(--gray-4);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const uploadFile = async (file) => {
    let data = new FormData();
    data.append("files", file);

    const response = await api.post("/upload", data);

    return response.data[0];
};

export const File = (props) => (
    <FileGrid width={`${props.width || 200}px`} className="grid-file">
        <span style={props.labelStyle}>{props.label}</span>
        <Dropzone accept={props.accept} onDropAccepted={props.onUpload}>
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <DropContainer
                    {...getRootProps()}
                    isDragActive={isDragActive}
                    isDragReject={isDragReject}
                    height={props.height}
                >
                    {props.file.preview ? (
                        props.fileType === "pdf" ? (
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                                <Viewer
                                    fileUrl={props.file.preview}
                                    defaultScale={props.scale}
                                />
                            </Worker>
                        ) : (
                            <img src={props.file.preview} alt="Input" />
                        )
                    ) : props.fileType === "pdf" ? (
                        <div className="d-flex flex-column justify-content-center align-items-center ">
                            <FaFilePdf className="empty-file" size="64" />
                            <span>
                                Arraste e solte <br /> ou clique aqui
                            </span>
                        </div>
                    ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center ">
                            <FaImage className="empty-file" size="64" />
                            <span>
                                Arraste e solte <br /> ou clique aqui
                            </span>
                        </div>
                    )}
                    <input {...getInputProps()} />
                </DropContainer>
            )}
        </Dropzone>
        <FileControl>
            <DeleteFile
                className={!props.file.preview && "file-disabled"}
                onClick={props.onDeleteFile}
            >
                <FaTrash />
            </DeleteFile>
            <ViewFile className={!props.file.preview && "file-disabled"}>
                <a
                    href={props.file.preview}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaEye />
                </a>
            </ViewFile>
        </FileControl>
    </FileGrid>
);

export default Index;

import React from "react";
import Slider from "react-slick";

const Index = ({ children }) => {
    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2500,
        autoplaySpeed: 2500,
        cssEase: "linear",
        pauseOnHover: false,
    };

    return <Slider {...settings}>{children}</Slider>;
};

export default Index;
